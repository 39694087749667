// vue
import { ref, reactive, computed, onMounted } from 'vue';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useMarketplace } from '@store/ts/marketplace';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// services
import Marketplace from '@services/marketplace';
import LocalStorage from '@services/ts/localStorage';

// router
import { useRoute, useRouter } from 'vue-router';

// naive-ui
import {
    useMessage } from 'naive-ui';

export default function () {
    // store
    const gl = useGl();
    const refs = useRefs();
    const marketplace = useMarketplace();

    // naive-ui
    

    // i18n
    const { t } = useI18n();

    // router
    const route = useRoute();

    // vars
    const { _ } = window;
    const init = ref(false);
    const editedBotId = ref();
    const deletedBotId = ref();
    const showModalEdit = ref(false);
    const showModalDelete = ref(false);

    // storage filters
    const storeFilterKey = '___filters_marketplace-sell';
    let storeFilters = LocalStorage.getItem(storeFilterKey);

    // set filter settings
    if (storeFilters == null) {
        LocalStorage.setItem(storeFilterKey, CRUD_PAGER_FACTORY({
            exchanges: [ -1],
            algos: [ -1 ],
            last_conf_changed_date: [ -1 ],
            in_market_state: [ -1 ],
        }));

        storeFilters = LocalStorage.getItem(storeFilterKey);
    }

    // filters
    const filters = reactive({
        ...storeFilters,
    });

    const resetFilters = () => {
        filters.filters.exchanges = [ -1 ];
        filters.filters.algos = [ -1 ];
        filters.filters.last_conf_changed_date = [ -1 ];
        filters.filters.in_market_state = [ -1 ];

        getData();
    };
    const filtersChanged = computed(() => {
        return filters.filters.exchanges[0] !== -1
          || filters.filters.algos[0] !== -1
          || filters.filters.last_conf_changed_date[0] !== -1
          || filters.filters.in_market_state[0] !== -1;
    });

    const showModal = computed(() => !!route.params.id);
    const parsed = computed(() => {
        var arr = marketplace.localization['marketplace/sell/info']
            .slice(1)
            .slice(0, -1)
            .split(/></g);

        //split removes the >< so we need to determine where to put them back in.
        for(var i = 0; i < arr.length; i++){
            if(arr[i].substring(0, 1) != '<'){
                arr[i] = '<' + arr[i];
            }

            if(arr[i].slice(-1) != '>'){
                arr[i] = arr[i] + '>';
            }
        }

        return arr;
    });

    const refFilters = computed(() => [
        {
            mode: 'exchanges',
            title: refs.localization.filters.marketplace.filters_marketplace_exchanges_label,
            refs: refs.exchanges,
            filter: 'filters.exchanges',
            seeable: filters.filters.exchanges[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.marketplace.filters_marketplace_algo_label,
            refs: marketplace.algos,
            filter: 'filters.algos',
            seeable: filters.filters.algos[0] !== -1,
        }, {
            mode: 'radio_group',
            title: refs.localization.filters.marketplace.filters_marketplace_available_in_marketplace_label,
            refs: marketplace.inMarketStates,
            value: filters.filters.in_market_state,
            filter: 'filters.in_market_state',
            seeable: filters.filters.in_market_state[0] !== -1,
        }, {
            mode: 'radio_group',
            title: refs.localization.filters.marketplace.filters_marketplace_configuration_without_changes_label,
            refs: marketplace.periods,
            value: filters.filters.last_conf_changed_date,
            filter: 'filters.last_conf_changed_date',
            seeable: filters.filters.last_conf_changed_date[0] !== -1,
        },
    ]);

    const actions = computed(() => [
        'marketplace_Sell__addToMarket',
        'marketplace_Sell__statistics',
        'marketplace_Sell__edit']);

    const tryEditBot = ($event) => {
        editedBotId.value = $event;
        showModalEdit.value = true;
    };

    const editBot = () => {
        // route to edti bot
        console.log('route to edti bot');
    };

    const deleteBot = async () => {
        gl.showLoading = true;

        await Marketplace.removeFromMarketplace(deletedBotId.value);
        await getData();

        gl.showLoading = false;
    };

    const changeFilter = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && marketplace.sellGet.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem('__filters_marketplace-sell', value);
        }

        _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
      
        if (update || canUpdate)
            getData();
    };

    const sortColumn = $event => {
        _.set(
            filters,
            'sort_column',
            $event.columnKey !== undefined && $event.order !== undefined
                ? $event.columnKey
                : '');

        _.set(
            filters,
            'sort_dir',
            $event.order !== undefined
                ? $event.order
                : '');

        getData();
    };

    const getData = async () => {
        gl.showLoading = true;

        // save filters
        LocalStorage.setItem(storeFilterKey, filters);

        try {
            const prepare = await Marketplace.sellGet({
                pager: filters,
            });

            if (marketplace.sellGetNotEmpty) {
                Object.keys(prepare.data).forEach(key => {
                    if (!['columns'].includes(key)) {
                        marketplace.sellGet[key] = prepare.data[key];
                    }
                });
            } else {
                marketplace.sellGet = prepare.data;
            }

            if (marketplace.sellGet.records.length)
                marketplace.sellGet.records.push({
                    type: 'footer',
                    ...marketplace.sellGet.totals,
                });
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const doSomething = ([ action, value ]) => {
        const fns = {
            tryDeleteBot: () => {
                deletedBotId.value = value;
                showModalDelete.value = true;
            },
            tryEditBot: () => {
                editedBotId.value = value;
                showModalEdit.value = true;
            },
        };

        fns[action]();
    };

    const cancelDeleteBot = () => {
        deletedBotId.value = null;
        showModalDelete.value = false;
    };

    const confirmlDeleteBot = () => {
        showModalDelete.value = false;
        deleteBot();
    };

    onMounted( async () => {
        // cache
        // if (!marketplace.sellGet)
        await getData();

        init.value = true;
    });

    return {
        gl,
        refs,
        init,
        parsed,
        filters,
        actions,
        showModal,
        refFilters,
        editedBotId,
        marketplace,
        deletedBotId,
        showModalEdit,
        filtersChanged,
        showModalDelete,
        getData,
        editBot,
        deleteBot,
        tryEditBot,
        sortColumn,
        resetFilters,
        changeFilter,
        doSomething,
        cancelDeleteBot,
        confirmlDeleteBot,
    };
}