<template>
<base-modal-desktop
    :label="marketplace.localization['marketplace/sell/review/title']"
    :show="show"
    :lightDark="false"
    width="1000px"
    height="98vh"
    @update:show="router.go(-1)"
    @after-leave="init = false">
    <template #default>
        <div v-if="!init" class="flex h-full justify-center">
            <n-spin size="small" />
        </div>
        <template v-else>
            <n-alert
                class="bg-[#eaf0fe] dark:bg-blue-900 dark:bg-opacity-20 rounded-lg" type="info">
                <div class="notes text-xs mt-[2px] text-gray-600 dark:text-white/75" v-html="confIntro"></div>
            </n-alert>

            <section class="flex items-end mt-4">
                <rb-input
                    :label="fieldModel.price.title"
                    :placeholder="fieldModel.price.placeholder"
                    :status="fieldModel.price.status === 'error' ? 'error' : undefined"
                    :msg="fieldModel.price.msg"
                    v-model:value="fieldModel.price.value"
                    @update:value="fieldModel.price.status = undefined, fieldModel.price.msg = undefined, filterNonNumeric()">
                    <template #suffix><rb-coin class="w-5 ml-2" :coin="currency(env.profitSymbol)" /></template>
                </rb-input>

                <div v-if="fieldModel.price.value" class="flex flex-nowrap justify-center items-center ml-4 mb-[2px]">
                    <div class="text-2xl mr-4 whitespace-nowrap text-gray-600 dark:text-white/75">
                        <span>- {{ rbCommissionPercents }} %</span>
                        <span class="text-main"> = {{ realPrice }}</span>
                    </div>
                    <rb-coin class="mr-4 w-6" :coin="currency(env.profitSymbol)" />
                </div>
            </section>

            <div class="text-sm text-gray-600 dark:text-white/75 mt-4">
                <span v-html="marketplace.localization['marketplace/sell/review/full_income/title']"></span>
                <span class="text-main font-semibold">&nbsp;{{ realPrice }} {{ env.profitSymbol }}</span>
            </div>
            <n-divider />

            <n-alert v-if="marketplace.localization['marketplace/sell/review/notes/info']" class="rounded-lg mt-4" type="warning" size="large">
                <template #header>
                    <n-collapse arrow-placement="right">
                        <n-collapse-item>
                            <template #header>
                                <div class="text-xs"
                                    v-html="parsed(marketplace.localization['marketplace/sell/review/notes/info'])[0] + parsed(marketplace.localization['marketplace/sell/review/notes/info'])[1]"></div>
                            </template>
                            <div
                                class="notes text-xs"
                                v-html="parsed(marketplace.localization['marketplace/sell/review/notes/info']).slice(2).join('')">
                            </div>
                        </n-collapse-item>
                    </n-collapse>
                </template>
            </n-alert>

            <div
                class="mt-4"
                :class="fieldModel.notes.status === 'error' ? 'border border-red-400' : ''">
                <quill-editor
                    theme="snow"
                    contentType="html"
                    v-model:content="fieldModel.notes.value">
                </quill-editor>
                <div v-if="fieldModel.notes.status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ fieldModel.notes.msg }}</div>
            </div>
            <n-divider />

            <n-alert v-if="marketplace.localization['marketplace/sell/review/owner/info']" class="rounded-lg mt-4" type="warning" size="large">
                <div class="notes text-xs" v-html="marketplace.localization['marketplace/sell/review/owner/info']"></div>
            </n-alert>

            <rb-input
                id="owner_contact"
                class="mt-4"
                :label="fieldModel.owner_contact.title"
                :placeholder="fieldModel.owner_contact.placeholder"
                :status="fieldModel.owner_contact.status === 'error' ? 'error' : undefined"
                :msg="fieldModel.owner_contact.msg"
                v-model:value="fieldModel.owner_contact.value"
                @update:value="fieldModel.owner_contact.status = undefined, fieldModel.owner_contact.msg = undefined" />
        </template>
    </template>
    <template #footer>
        <div v-if="!newBotId && init" class="flex justify-end">
            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :loading="sellAPILoading"
                :disabled="!canSellConfiguration || sellAPILoading"
                @click="onSellConfiguration">
                {{ marketplace.localization['marketplace/sell/review/buttons/sell'] }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useMarketplace } from '@store/ts/marketplace';

// editor
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// router
import { useRoute, useRouter } from 'vue-router';

// naive-ui
import {
    NIcon,
    NSpin,
    NCard,
    NInput,
    NAlert,
    NButton,
    NDivider,
    NCollapse,
    useMessage,
    NCollapseItem } from 'naive-ui';

// services
import MarketplaceService from '@services/marketplace';

// components
import RbCoin from '@/components/rb-coin';
import RbInput from '@components/rb-input';

// icons
import { Warning24Filled } from '@vicons/fluent';

export default {
    name: 'marketplace-sell-review',
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        NIcon,
        NSpin,
        NCard,
        NInput,
        NAlert,
        RbCoin,
        NButton,
        RbInput,
        NDivider,
        NCollapse,
        QuillEditor,
        NCollapseItem,
        Warning24Filled,
    },
    setup(props, { emit }) {
        // router
        const route = useRoute();
        const router = useRouter();

        // store
        const gl = useGl();
        const env = useEnv();
        const refs = useRefs();
        const marketplace = useMarketplace();

        // vars
        const bot = ref();
        const editor = ref();
        const init = ref(false);
        const sellAPILoading = ref(false);
        const rbCommissionPercents = ref(0);

        

        watch(() => gl.darkTheme, () => {
            // editor.value.rerender(editorOptions.value);
        });

        watch(() => props.show, v => {
            if (v) {
                getData();
            } else {
                fieldModel.price.value = '';
                fieldModel.owner_contact.value = '';
                fieldModel.notes.value = '';
            }
        });

        const fieldModel = reactive({
            price: {
                value: '',
                msg: undefined,
                placeholder: '',
                status: undefined,
                title: marketplace.localization['marketplace/review/config_price'],
            },
            owner_contact: {
                value: '',
                msg: undefined,
                placeholder: '',
                title:  marketplace.localization['marketplace/sell/review/owner/label'],
                status: undefined,
            },
            notes: {
                value: '',
                placeholder: '',
                msg: undefined,
                status: undefined,
                title: undefined,
            },
        });

        const id = computed(() => route.params.id);

        const editorOptions = computed(() => ({
            height: 400,
            menubar: false,
            auto_focus: false,
            skin: gl.darkTheme ? 'oxide-dark' : '',
            content_css: gl.darkTheme ? 'dark' : '',
            plugins: [
                'autolink lists link anchor code',
            ],
            toolbar:
                'undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | code',
        }));

        const canSellConfiguration = computed(() =>
            fieldModel.price.value !== ''
            && fieldModel.owner_contact.value
            && fieldModel.notes.value);
            // && fieldModel.notes.value.length >= 100

        const confIntro = computed(() => {
            let intro = marketplace.localization['marketplace/buy/review/intro'];

            if (bot.value)
                Object.keys(bot.value.data.general)
                    .forEach(key => intro = intro.split('{' + key + '}').join(bot.value.data.general[key]));

            return intro;
        });

        const format = (v, p) => {
            if (!v || !p) return v;
            if (String(v).indexOf('.') === -1 && String(v).toLowerCase().indexOf('e') === -1) return v;
    
            return Number(v).toFixed(p).replace(/0*$/,'');
        };

        const realPrice = computed(() => {
            const rPrice = format(Number(fieldModel.price.value) - (Number(fieldModel.price.value) * Number(rbCommissionPercents.value) / 100), 10);
            return isFinite(parseFloat(fieldModel.price.value, 10)) && !isNaN(fieldModel.price.value) ? rPrice : '';
        });

        const getData = async () => {
            bot.value = await MarketplaceService.getFullInfoForSell(id.value);

            rbCommissionPercents.value = bot.value.data.rb_commission_percents;
            fieldModel.price.value = bot.value.data.general.config_price;
            fieldModel.notes.value = bot.value.data.general.notes;
            fieldModel.owner_contact.value = bot.value.data.general.owner_contact;

            init.value = true;
        };

        const currency = (icon) => {
            return refs.currencies.find(el => el.id === icon);
        };

        const onSellConfiguration = async () => {
            sellAPILoading.value = true;

            const conf = {
                bot_id: bot.value.data.general.id,
                price: fieldModel.price.value,
                notes: fieldModel.notes.value,
                has_feedback: String(fieldModel.owner_contact.value).trim().length > 0,
                owner_contact: fieldModel.owner_contact.value,
                
                _formPath: 'market.sell.review',
            };

            const prepare = await MarketplaceService.sellBotConfiguration(conf);

            if (!prepare.data.status) {
                if (prepare.data?.errors_form) {

                    for (let key in fieldModel) {
                        const fields = prepare.data.errors_form['market.sell.review'].fields;
                        const el = Object.keys(fields).find(el => el === key);

                        if (el) {
                            fieldModel[key].status = 'error';
                            fieldModel[key].msg = fields[el].msg;
                        } else {
                            fieldModel[key].status = 'success';
                            fieldModel[key].msg = undefined;
                        }
                    }
                }
            } else {
                // show messages
                prepare.postMessages.forEach(el => {
                    gl.showNotification({
                        type: el.success ? 'success' : 'error',
                        msg: el.msg,
                    });
                });

                router.push({ name: 'marketplace.sell' });

                emit('getData');
            }

            sellAPILoading.value = false;
        };

        const filterNonNumeric = () => {
            fieldModel.price.value = fieldModel.price.value.replace(/[^0-9.]/g, '');
        };

        const parsed = v => {
            if (!v) return;

            var arr = v.split(/></g);

            //split removes the >< so we need to determine where to put them back in.
            for(var i = 0; i < arr.length; i++){
                if(arr[i].substring(0, 1) != '<'){
                    arr[i] = '<' + arr[i];
                }

                if(arr[i].slice(-1) != '>'){
                    arr[i] = arr[i] + '>';
                }
            }

            return arr;
        };

        onMounted(async () => {
            id.value
                ? getData()
                : router.push({ name : 'marketplace.sell' });
        });

        return {
            gl,
            bot,
            env,
            refs,
            init,
            router,
            editor,
            realPrice,
            confIntro,
            fieldModel,
            marketplace,
            editorOptions,
            sellAPILoading,
            canSellConfiguration,
            rbCommissionPercents,
            parsed,
            currency,
            filterNonNumeric,
            onSellConfiguration,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(a) {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

:deep(.conf-intro) {
    p:not(:first-child) {
        margin-top: 12px;
    }

    b {
        color: #4949D9;
        text-transform: uppercase;
    }

    a {
        color: #4949D9;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

:deep(a) {
    color: #4949D9;
}
</style>